import css from 'styled-jsx/css'

export const ButtonStyle = css.global`
  .ant-btn {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-color: #d9d9d9;
  }
  .ant-btn > .anticon {
    line-height: 1;
  }
  .ant-btn,
  .ant-btn:active,
  .ant-btn:focus {
    outline: 0;
  }
  .ant-btn:not([disabled]):hover {
    text-decoration: none;
  }
  .ant-btn:not([disabled]):active {
    outline: 0;
    box-shadow: none;
  }
  .ant-btn[disabled] {
    cursor: not-allowed;
  }
  .ant-btn[disabled] > * {
    pointer-events: none;
  }
  .ant-btn-lg {
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
    border-radius: 5px;
  }
  .ant-btn-sm {
    height: 24px;
    padding: 0px 7px;
    font-size: 14px;
    border-radius: 5px;
  }
  .ant-btn > a:only-child {
    color: currentColor;
  }
  .ant-btn > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn:hover,
  .ant-btn:focus {
    color: #40a9ff;
    background: #fff;
    border-color: #40a9ff;
  }
  .ant-btn:hover > a:only-child,
  .ant-btn:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn:hover > a:only-child::after,
  .ant-btn:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn:active {
    color: #096dd9;
    background: #fff;
    border-color: #096dd9;
  }
  .ant-btn:active > a:only-child {
    color: currentColor;
  }
  .ant-btn:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn[disabled] > a:only-child,
  .ant-btn[disabled]:hover > a:only-child,
  .ant-btn[disabled]:focus > a:only-child,
  .ant-btn[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn[disabled] > a:only-child::after,
  .ant-btn[disabled]:hover > a:only-child::after,
  .ant-btn[disabled]:focus > a:only-child::after,
  .ant-btn[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    text-decoration: none;
    background: #fff;
  }
  .ant-btn > span {
    display: inline-block;
  }
  .ant-btn-primary {
    color: #fff;
    background: #1890ff;
    border-color: #1890ff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .ant-btn-primary > a:only-child {
    color: currentColor;
  }
  .ant-btn-primary > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    color: #fff;
    background: #40a9ff;
    border-color: #40a9ff;
  }
  .ant-btn-primary:hover > a:only-child,
  .ant-btn-primary:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-primary:hover > a:only-child::after,
  .ant-btn-primary:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-primary:active {
    color: #fff;
    background: #096dd9;
    border-color: #096dd9;
  }
  .ant-btn-primary:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-primary:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-primary[disabled] > a:only-child,
  .ant-btn-primary[disabled]:hover > a:only-child,
  .ant-btn-primary[disabled]:focus > a:only-child,
  .ant-btn-primary[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-primary[disabled] > a:only-child::after,
  .ant-btn-primary[disabled]:hover > a:only-child::after,
  .ant-btn-primary[disabled]:focus > a:only-child::after,
  .ant-btn-primary[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
    border-right-color: #40a9ff;
    border-left-color: #40a9ff;
  }
  .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
    border-color: #d9d9d9;
  }
  .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
    border-right-color: #40a9ff;
  }
  .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
    border-right-color: #d9d9d9;
  }
  .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
  .ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-left-color: #40a9ff;
  }
  .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
  .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
    border-left-color: #d9d9d9;
  }
  .ant-btn-ghost {
    color: rgba(0, 0, 0, 0.85);
    background: transparent;
    border-color: #d9d9d9;
  }
  .ant-btn-ghost > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-ghost:hover,
  .ant-btn-ghost:focus {
    color: #40a9ff;
    background: transparent;
    border-color: #40a9ff;
  }
  .ant-btn-ghost:hover > a:only-child,
  .ant-btn-ghost:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost:hover > a:only-child::after,
  .ant-btn-ghost:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-ghost:active {
    color: #096dd9;
    background: transparent;
    border-color: #096dd9;
  }
  .ant-btn-ghost:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-ghost[disabled],
  .ant-btn-ghost[disabled]:hover,
  .ant-btn-ghost[disabled]:focus,
  .ant-btn-ghost[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-ghost[disabled] > a:only-child,
  .ant-btn-ghost[disabled]:hover > a:only-child,
  .ant-btn-ghost[disabled]:focus > a:only-child,
  .ant-btn-ghost[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost[disabled] > a:only-child::after,
  .ant-btn-ghost[disabled]:hover > a:only-child::after,
  .ant-btn-ghost[disabled]:focus > a:only-child::after,
  .ant-btn-ghost[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dashed {
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-color: #d9d9d9;
    border-style: dashed;
  }
  .ant-btn-dashed > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dashed:hover,
  .ant-btn-dashed:focus {
    color: #40a9ff;
    background: #fff;
    border-color: #40a9ff;
  }
  .ant-btn-dashed:hover > a:only-child,
  .ant-btn-dashed:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed:hover > a:only-child::after,
  .ant-btn-dashed:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dashed:active {
    color: #096dd9;
    background: #fff;
    border-color: #096dd9;
  }
  .ant-btn-dashed:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dashed[disabled],
  .ant-btn-dashed[disabled]:hover,
  .ant-btn-dashed[disabled]:focus,
  .ant-btn-dashed[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dashed[disabled] > a:only-child,
  .ant-btn-dashed[disabled]:hover > a:only-child,
  .ant-btn-dashed[disabled]:focus > a:only-child,
  .ant-btn-dashed[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed[disabled] > a:only-child::after,
  .ant-btn-dashed[disabled]:hover > a:only-child::after,
  .ant-btn-dashed[disabled]:focus > a:only-child::after,
  .ant-btn-dashed[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-danger {
    color: #fff;
    background: #ff4d4f;
    border-color: #ff4d4f;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .ant-btn-danger > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-danger:hover,
  .ant-btn-danger:focus {
    color: #fff;
    background: #ff7875;
    border-color: #ff7875;
  }
  .ant-btn-danger:hover > a:only-child,
  .ant-btn-danger:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger:hover > a:only-child::after,
  .ant-btn-danger:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-danger:active {
    color: #fff;
    background: #d9363e;
    border-color: #d9363e;
  }
  .ant-btn-danger:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-danger[disabled],
  .ant-btn-danger[disabled]:hover,
  .ant-btn-danger[disabled]:focus,
  .ant-btn-danger[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-danger[disabled] > a:only-child,
  .ant-btn-danger[disabled]:hover > a:only-child,
  .ant-btn-danger[disabled]:focus > a:only-child,
  .ant-btn-danger[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger[disabled] > a:only-child::after,
  .ant-btn-danger[disabled]:hover > a:only-child::after,
  .ant-btn-danger[disabled]:focus > a:only-child::after,
  .ant-btn-danger[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link {
    color: #1890ff;
    background: transparent;
    border-color: transparent;
    box-shadow: none;
  }
  .ant-btn-link > a:only-child {
    color: currentColor;
  }
  .ant-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link:hover,
  .ant-btn-link:focus {
    color: #40a9ff;
    background: transparent;
    border-color: #40a9ff;
  }
  .ant-btn-link:hover > a:only-child,
  .ant-btn-link:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-link:hover > a:only-child::after,
  .ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link:active {
    color: #096dd9;
    background: transparent;
    border-color: #096dd9;
  }
  .ant-btn-link:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-link:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link[disabled],
  .ant-btn-link[disabled]:hover,
  .ant-btn-link[disabled]:focus,
  .ant-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-link[disabled] > a:only-child,
  .ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-link[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-link[disabled] > a:only-child::after,
  .ant-btn-link[disabled]:hover > a:only-child::after,
  .ant-btn-link[disabled]:focus > a:only-child::after,
  .ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link:hover {
    background: transparent;
  }
  .ant-btn-link:hover,
  .ant-btn-link:focus,
  .ant-btn-link:active {
    border-color: transparent;
  }
  .ant-btn-link[disabled],
  .ant-btn-link[disabled]:hover,
  .ant-btn-link[disabled]:focus,
  .ant-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
    border-color: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-link[disabled] > a:only-child,
  .ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-link[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-link[disabled] > a:only-child::after,
  .ant-btn-link[disabled]:hover > a:only-child::after,
  .ant-btn-link[disabled]:focus > a:only-child::after,
  .ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-text {
    color: rgba(0, 0, 0, 0.85);
    background: transparent;
    border-color: transparent;
    box-shadow: none;
  }
  .ant-btn-text > a:only-child {
    color: currentColor;
  }
  .ant-btn-text > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-text:hover,
  .ant-btn-text:focus {
    color: #40a9ff;
    background: transparent;
    border-color: #40a9ff;
  }
  .ant-btn-text:hover > a:only-child,
  .ant-btn-text:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-text:hover > a:only-child::after,
  .ant-btn-text:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-text:active {
    color: #096dd9;
    background: transparent;
    border-color: #096dd9;
  }
  .ant-btn-text:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-text:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-text[disabled],
  .ant-btn-text[disabled]:hover,
  .ant-btn-text[disabled]:focus,
  .ant-btn-text[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-text[disabled] > a:only-child,
  .ant-btn-text[disabled]:hover > a:only-child,
  .ant-btn-text[disabled]:focus > a:only-child,
  .ant-btn-text[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-text[disabled] > a:only-child::after,
  .ant-btn-text[disabled]:hover > a:only-child::after,
  .ant-btn-text[disabled]:focus > a:only-child::after,
  .ant-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-text:hover,
  .ant-btn-text:focus {
    color: rgba(0, 0, 0, 0.85);
    background: rgba(0, 0, 0, 0.018);
    border-color: transparent;
  }
  .ant-btn-text:active {
    color: rgba(0, 0, 0, 0.85);
    background: rgba(0, 0, 0, 0.028);
    border-color: transparent;
  }
  .ant-btn-text[disabled],
  .ant-btn-text[disabled]:hover,
  .ant-btn-text[disabled]:focus,
  .ant-btn-text[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
    border-color: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-text[disabled] > a:only-child,
  .ant-btn-text[disabled]:hover > a:only-child,
  .ant-btn-text[disabled]:focus > a:only-child,
  .ant-btn-text[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-text[disabled] > a:only-child::after,
  .ant-btn-text[disabled]:hover > a:only-child::after,
  .ant-btn-text[disabled]:focus > a:only-child::after,
  .ant-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous {
    color: #ff4d4f;
    background: #fff;
    border-color: #ff4d4f;
  }
  .ant-btn-dangerous > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous:hover,
  .ant-btn-dangerous:focus {
    color: #ff7875;
    background: #fff;
    border-color: #ff7875;
  }
  .ant-btn-dangerous:hover > a:only-child,
  .ant-btn-dangerous:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous:hover > a:only-child::after,
  .ant-btn-dangerous:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous:active {
    color: #d9363e;
    background: #fff;
    border-color: #d9363e;
  }
  .ant-btn-dangerous:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous[disabled],
  .ant-btn-dangerous[disabled]:hover,
  .ant-btn-dangerous[disabled]:focus,
  .ant-btn-dangerous[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dangerous[disabled] > a:only-child,
  .ant-btn-dangerous[disabled]:hover > a:only-child,
  .ant-btn-dangerous[disabled]:focus > a:only-child,
  .ant-btn-dangerous[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous[disabled] > a:only-child::after,
  .ant-btn-dangerous[disabled]:hover > a:only-child::after,
  .ant-btn-dangerous[disabled]:focus > a:only-child::after,
  .ant-btn-dangerous[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-primary {
    color: #fff;
    background: #ff4d4f;
    border-color: #ff4d4f;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .ant-btn-dangerous.ant-btn-primary > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-primary > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-primary:hover,
  .ant-btn-dangerous.ant-btn-primary:focus {
    color: #fff;
    background: #ff7875;
    border-color: #ff7875;
  }
  .ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-primary:active {
    color: #fff;
    background: #d9363e;
    border-color: #d9363e;
  }
  .ant-btn-dangerous.ant-btn-primary:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-primary[disabled],
  .ant-btn-dangerous.ant-btn-primary[disabled]:hover,
  .ant-btn-dangerous.ant-btn-primary[disabled]:focus,
  .ant-btn-dangerous.ant-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
  .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
  .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
  .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-link {
    color: #ff4d4f;
    background: transparent;
    border-color: transparent;
    box-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-link > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-link:hover,
  .ant-btn-dangerous.ant-btn-link:focus {
    color: #40a9ff;
    background: transparent;
    border-color: #40a9ff;
  }
  .ant-btn-dangerous.ant-btn-link:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-link:active {
    color: #096dd9;
    background: transparent;
    border-color: #096dd9;
  }
  .ant-btn-dangerous.ant-btn-link:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-link[disabled],
  .ant-btn-dangerous.ant-btn-link[disabled]:hover,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
  .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-link:hover,
  .ant-btn-dangerous.ant-btn-link:focus {
    color: #ff7875;
    background: transparent;
    border-color: transparent;
  }
  .ant-btn-dangerous.ant-btn-link:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-link:active {
    color: #d9363e;
    background: transparent;
    border-color: transparent;
  }
  .ant-btn-dangerous.ant-btn-link:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-link[disabled],
  .ant-btn-dangerous.ant-btn-link[disabled]:hover,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
    border-color: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
  .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-text {
    color: #ff4d4f;
    background: transparent;
    border-color: transparent;
    box-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-text > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-text:hover,
  .ant-btn-dangerous.ant-btn-text:focus {
    color: #40a9ff;
    background: transparent;
    border-color: #40a9ff;
  }
  .ant-btn-dangerous.ant-btn-text:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-text:active {
    color: #096dd9;
    background: transparent;
    border-color: #096dd9;
  }
  .ant-btn-dangerous.ant-btn-text:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-text[disabled],
  .ant-btn-dangerous.ant-btn-text[disabled]:hover,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus,
  .ant-btn-dangerous.ant-btn-text[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-text:hover,
  .ant-btn-dangerous.ant-btn-text:focus {
    color: #ff7875;
    background: rgba(0, 0, 0, 0.018);
    border-color: transparent;
  }
  .ant-btn-dangerous.ant-btn-text:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-text:active {
    color: #d9363e;
    background: rgba(0, 0, 0, 0.028);
    border-color: transparent;
  }
  .ant-btn-dangerous.ant-btn-text:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-text[disabled],
  .ant-btn-dangerous.ant-btn-text[disabled]:hover,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus,
  .ant-btn-dangerous.ant-btn-text[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
    border-color: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-icon-only {
    width: 32px;
    height: 32px;
    padding: 2.4px 0;
    font-size: 16px;
    border-radius: 5px;
    vertical-align: -1px;
  }
  .ant-btn-icon-only > * {
    font-size: 16px;
  }
  .ant-btn-icon-only.ant-btn-lg {
    width: 40px;
    height: 40px;
    padding: 4.9px 0;
    font-size: 18px;
    border-radius: 5px;
  }
  .ant-btn-icon-only.ant-btn-lg > * {
    font-size: 18px;
  }
  .ant-btn-icon-only.ant-btn-sm {
    width: 24px;
    height: 24px;
    padding: 0px 0;
    font-size: 14px;
    border-radius: 5px;
  }
  .ant-btn-icon-only.ant-btn-sm > * {
    font-size: 14px;
  }
  .ant-btn-round {
    height: 32px;
    padding: 4px 16px;
    font-size: 14px;
    border-radius: 32px;
  }
  .ant-btn-round.ant-btn-lg {
    height: 40px;
    padding: 6.4px 20px;
    font-size: 16px;
    border-radius: 40px;
  }
  .ant-btn-round.ant-btn-sm {
    height: 24px;
    padding: 0px 12px;
    font-size: 14px;
    border-radius: 24px;
  }
  .ant-btn-round.ant-btn-icon-only {
    width: auto;
  }
  .ant-btn-circle,
  .ant-btn-circle-outline {
    min-width: 32px;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    border-radius: 50%;
  }
  .ant-btn-circle.ant-btn-lg,
  .ant-btn-circle-outline.ant-btn-lg {
    min-width: 40px;
    border-radius: 50%;
  }
  .ant-btn-circle.ant-btn-sm,
  .ant-btn-circle-outline.ant-btn-sm {
    min-width: 24px;
    border-radius: 50%;
  }
  .ant-btn::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    display: none;
    background: #fff;
    border-radius: inherit;
    opacity: 0.35;
    transition: opacity 0.2s;
    content: '';
    pointer-events: none;
  }
  .ant-btn .anticon {
    transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-btn .anticon.anticon-plus > svg,
  .ant-btn .anticon.anticon-minus > svg {
    shape-rendering: optimizeSpeed;
  }
  .ant-btn.ant-btn-loading {
    position: relative;
  }
  .ant-btn.ant-btn-loading:not([disabled]) {
    pointer-events: none;
  }
  .ant-btn.ant-btn-loading::before {
    display: block;
  }
  .ant-btn > .ant-btn-loading-icon {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-btn > .ant-btn-loading-icon .anticon {
    -webkit-animation: none;
    animation: none;
    padding-right: 8px;
  }
  .ant-btn > .ant-btn-loading-icon .anticon svg {
    -webkit-animation: loadingCircle 1s linear infinite;
    animation: loadingCircle 1s linear infinite;
  }
  .ant-btn > .ant-btn-loading-icon:only-child .anticon {
    padding-right: 0;
  }
  .ant-btn-group {
    position: relative;
    display: inline-flex;
  }
  .ant-btn-group > .ant-btn,
  .ant-btn-group > span > .ant-btn {
    position: relative;
  }
  .ant-btn-group > .ant-btn:hover,
  .ant-btn-group > span > .ant-btn:hover,
  .ant-btn-group > .ant-btn:focus,
  .ant-btn-group > span > .ant-btn:focus,
  .ant-btn-group > .ant-btn:active,
  .ant-btn-group > span > .ant-btn:active {
    z-index: 2;
  }
  .ant-btn-group > .ant-btn[disabled],
  .ant-btn-group > span > .ant-btn[disabled] {
    z-index: 0;
  }
  .ant-btn-group .ant-btn-icon-only {
    font-size: 14px;
  }
  .ant-btn-group-lg > .ant-btn,
  .ant-btn-group-lg > span > .ant-btn {
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
    border-radius: 0;
  }
  .ant-btn-group-lg .ant-btn.ant-btn-icon-only {
    width: 40px;
    height: 40px;
    padding-right: 0;
    padding-left: 0;
  }
  .ant-btn-group-sm > .ant-btn,
  .ant-btn-group-sm > span > .ant-btn {
    height: 24px;
    padding: 0px 7px;
    font-size: 14px;
    border-radius: 0;
  }
  .ant-btn-group-sm > .ant-btn > .anticon,
  .ant-btn-group-sm > span > .ant-btn > .anticon {
    font-size: 14px;
  }
  .ant-btn-group-sm .ant-btn.ant-btn-icon-only {
    width: 24px;
    height: 24px;
    padding-right: 0;
    padding-left: 0;
  }
  .ant-btn-group .ant-btn + .ant-btn,
  .ant-btn + .ant-btn-group,
  .ant-btn-group span + .ant-btn,
  .ant-btn-group .ant-btn + span,
  .ant-btn-group > span + span,
  .ant-btn-group + .ant-btn,
  .ant-btn-group + .ant-btn-group {
    margin-left: -1px;
  }
  .ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
    border-left-color: transparent;
  }
  .ant-btn-group .ant-btn {
    border-radius: 0;
  }
  .ant-btn-group > .ant-btn:first-child,
  .ant-btn-group > span:first-child > .ant-btn {
    margin-left: 0;
  }
  .ant-btn-group > .ant-btn:only-child {
    border-radius: 5px;
  }
  .ant-btn-group > span:only-child > .ant-btn {
    border-radius: 5px;
  }
  .ant-btn-group > .ant-btn:first-child:not(:last-child),
  .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .ant-btn-group > .ant-btn:last-child:not(:first-child),
  .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .ant-btn-group-sm > .ant-btn:only-child {
    border-radius: 5px;
  }
  .ant-btn-group-sm > span:only-child > .ant-btn {
    border-radius: 5px;
  }
  .ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
  .ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
  .ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .ant-btn-group > .ant-btn-group {
    float: left;
  }
  .ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
    border-radius: 0;
  }
  .ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
    padding-right: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
    padding-left: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
  .ant-btn-rtl.ant-btn + .ant-btn-group,
  .ant-btn-rtl.ant-btn-group span + .ant-btn,
  .ant-btn-rtl.ant-btn-group .ant-btn + span,
  .ant-btn-rtl.ant-btn-group > span + span,
  .ant-btn-rtl.ant-btn-group + .ant-btn,
  .ant-btn-rtl.ant-btn-group + .ant-btn-group,
  .ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
  .ant-btn-group-rtl.ant-btn + .ant-btn-group,
  .ant-btn-group-rtl.ant-btn-group span + .ant-btn,
  .ant-btn-group-rtl.ant-btn-group .ant-btn + span,
  .ant-btn-group-rtl.ant-btn-group > span + span,
  .ant-btn-group-rtl.ant-btn-group + .ant-btn,
  .ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
    margin-right: -1px;
    margin-left: auto;
  }
  .ant-btn-group.ant-btn-group-rtl {
    direction: rtl;
  }
  .ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
  .ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 0;
  }
  .ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
  .ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px;
  }
  .ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
  .ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 0;
  }
  .ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
  .ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px;
  }
  .ant-btn:focus > span,
  .ant-btn:active > span {
    position: relative;
  }
  .ant-btn > .anticon + span,
  .ant-btn > span + .anticon {
    margin-left: 8px;
  }
  .ant-btn-background-ghost {
    color: #fff;
    background: transparent;
    border-color: #fff;
  }
  .ant-btn-background-ghost.ant-btn-primary {
    color: #1890ff;
    background: transparent;
    border-color: #1890ff;
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-primary > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-primary:hover,
  .ant-btn-background-ghost.ant-btn-primary:focus {
    color: #40a9ff;
    background: transparent;
    border-color: #40a9ff;
  }
  .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-primary:active {
    color: #096dd9;
    background: transparent;
    border-color: #096dd9;
  }
  .ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-primary[disabled],
  .ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-danger {
    color: #ff4d4f;
    background: transparent;
    border-color: #ff4d4f;
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-danger > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-danger:hover,
  .ant-btn-background-ghost.ant-btn-danger:focus {
    color: #ff7875;
    background: transparent;
    border-color: #ff7875;
  }
  .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-danger:active {
    color: #d9363e;
    background: transparent;
    border-color: #d9363e;
  }
  .ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-danger[disabled],
  .ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-dangerous {
    color: #ff4d4f;
    background: transparent;
    border-color: #ff4d4f;
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-dangerous:hover,
  .ant-btn-background-ghost.ant-btn-dangerous:focus {
    color: #ff7875;
    background: transparent;
    border-color: #ff7875;
  }
  .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-dangerous:active {
    color: #d9363e;
    background: transparent;
    border-color: #d9363e;
  }
  .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-dangerous[disabled],
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
    color: #ff4d4f;
    background: transparent;
    border-color: transparent;
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
    color: #ff7875;
    background: transparent;
    border-color: transparent;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
    color: #d9363e;
    background: transparent;
    border-color: transparent;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-two-chinese-chars::first-letter {
    letter-spacing: 0.34em;
  }
  .ant-btn-two-chinese-chars > *:not(.anticon) {
    margin-right: -0.34em;
    letter-spacing: 0.34em;
  }
  .ant-btn-block {
    width: 100%;
  }
  .ant-btn:empty {
    display: inline-block;
    width: 0;
    visibility: hidden;
    content: '\a0';
  }
  a.ant-btn {
    padding-top: 0.1px;
    line-height: 30px;
  }
  a.ant-btn-lg {
    line-height: 38px;
  }
  a.ant-btn-sm {
    line-height: 22px;
  }
  .ant-btn-rtl {
    direction: rtl;
  }
  .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
  .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-right-color: #40a9ff;
    border-left-color: #d9d9d9;
  }
  .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
  .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
    border-right-color: #d9d9d9;
    border-left-color: #40a9ff;
  }
  .ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon {
    padding-right: 0;
    padding-left: 8px;
  }
  .ant-btn > .ant-btn-loading-icon:only-child .anticon {
    padding-right: 0;
    padding-left: 0;
  }
  .ant-btn-rtl.ant-btn > .anticon + span,
  .ant-btn-rtl.ant-btn > span + .anticon {
    margin-right: 8px;
    margin-left: 0;
  }
`
export const SocialStyle = css.global`
  .ant-btn.btn-google,
  .ant-btn.btn-facebook {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: white;
  }
  .ant-btn.btn-google .icon,
  .ant-btn.btn-facebook .icon {
    color: #000;
    margin-top: 2px;
  }
  .ant-btn.btn-google .icon-facebook,
  .ant-btn.btn-facebook .icon-facebook {
    margin-right: 8px;
  }

  .ant-btn.btn-google {
    background-color: #fff;
    border-color: #e8e8e8;
    color: #000;
  }

  .ant-btn.btn-facebook {
    background-color: #4267b2;
    border-color: #4267b2;
  }

  body .ant-btn.btn-facebook:active,
  body .ant-btn.btn-facebook:hover {
    background-color: #4267b2;
    border-color: #4267b2;
    color: #fff;
  }
`
